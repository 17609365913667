<template>
  <el-dialog title="手机在网时长查询结果" width="500" :visible.sync="visible" :fullscreen="full" :close-on-click-modal="false" :close-on-press-escape="false" custom-class="v-dialog v-dialog--highLight risk-dialog" @open="computeHeight">
    <div>
      <tool :full-screen.sync="full" @download="download('手机在网时长查询结果')"></tool>
      <h2 class="risk-dialogTitle">手机在网时长查询结果</h2>
      <table class="risk-dialogTable">
        <colgroup>
          <col width="150"/>
          <col width="150"/>
          <col width="200"/>
        </colgroup>
        <tbody v-if="!!v.result.result">
          <tr>
            <td>手机号</td>
            <td>{{v.result.result.mobile}}</td>
          </tr>
          <tr>
            <td>运营商</td>
            <td>{{v.result.result.company}}</td>
          </tr>
          <tr>
            <td>省份</td>
            <td>{{v.result.result.province}}</td>
          </tr>
          <tr>
            <td>城市</td>
            <td>{{v.result.result.city}}</td>
          </tr>
          <tr>
            <td>在网时长描述</td>
            <td>{{v.result.result.desc}}</td>
          </tr>
          <tr>
            <td>查询时间</td>
            <td>{{miment().format('YYYY年MM月DD日 hh点mm分')}}</td>
          </tr>

        </tbody>
      </table>
    </div>

  </el-dialog>
</template>

<script>
import mixin from './mixin'
export default {
  data() {
    return {
      visible: false,
      v: null
    }
  },
  mixins: [mixin],
  methods: {
    open(datas) {
      this.visible = true
      this.v = Object.assign(this.v, datas)

      if (this.v.result.error_code) {
        this.$alert(this.v.result.reason)
      }
    }
  },
  created() {
    // 初始化数据
    this.v = {}
  }
}
</script>
